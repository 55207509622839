
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
// import { useQuery } from '@urql/vue';
import { Manga } from '@/types';

export default defineComponent({
  name: 'Archive',
  components: {
    NavBar
  },
  data() {
    return {
      data: {} as Manga,
      loading: true,
      error: false as string | false
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    /* Fetch data from the api */
    async getdata(callback?: () => void) {
      this.loading = true
      const data = {
        manga: (await this.axios.get(`/api/manga/${[this.$route.params.id].join()}.json`)).data
      }

      this.error = data.manga===null ? 'Manga non trovato' : false
      this.data = this.error||!data.manga ? this.data : data.manga
      /** onyl call if defined */
      callback && callback()
      this.loading =  false
    }
  }
})
